import { PropTypes } from 'prop-types';
import React from 'react';

import { Box } from '../../grid';
import { Icon } from '../../icons';
import { LoadingIndicator } from '../../icons/loading-indicator';
import { TextInput } from '../../input/text-input';

const AutocompleteInput = ({ autoFocus, loading, getInputProps, onBlur, placeholder, isError }) => (
  <TextInput
    {...getInputProps({
      autoFocus,
      placeholder,
    })}
    isError={isError}
    leftIcon={
      <Box ml="1rem">
        {loading ? <LoadingIndicator size="24px" /> : <Icon name="search" fill="greyPrimary" height="24px" />}
      </Box>
    }
    name="googleAutocompleteField"
    onBlur={onBlur}
    onKeyDown={null}
  />
);

AutocompleteInput.propTypes = {
  autoFocus: PropTypes.bool,
  getInputProps: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
};

AutocompleteInput.defaultProps = {
  autoFocus: false,
  loading: false,
  onBlur: undefined,
};

export { AutocompleteInput };
