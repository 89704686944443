import { get, isEmpty, isUndefined, trim } from 'lodash';
import { PropTypes } from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import React, { useState } from 'react';

import { AutocompleteInput } from '../autocomplete-input';
import { AutocompleteSuggestion } from '../autocomplete-suggestion';
import { AutocompleteSuggestionWrapper } from '../autocomplete-suggestion-wrapper';
import { deliveryAddressPropTypes } from '../../../prop-types';
import { getPlace } from '../get-place';
import { Label } from '../../typography';
import { LoadingIndicator } from '../../icons/loading-indicator';
import { useGoogleMaps } from '../../../hooks/use-google-maps';

const ADDRESS_TYPE_MAPPINGS = {
  subpremise: 'unit',
  street_number: 'streetNumber',
  route: 'streetName',
  locality: 'suburb',
  postal_code: 'postcode',
  administrative_area_level_1: 'state',
  country: 'country',
};

const getPlaceAddress = place => {
  const components = get(place, 'address_components', []);
  const address = components.reduce((current, { long_name: value, types }) => {
    const type = types.find(addressType => ADDRESS_TYPE_MAPPINGS[addressType]);

    if (!type) return current;

    const key = ADDRESS_TYPE_MAPPINGS[type];
    return { [key]: value, ...current };
  }, {});

  return address;
};

const AddressAutocomplete = ({
  countryCode,
  predefinedPlaces,
  handleAddressSelection,
  showConfirmationModal,
  handleConfirmStreetAddress,
}) => {
  const [address, setAddress] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const googleMaps = useGoogleMaps();

  const handleBlur = () => {
    setIsActive(false);
    setIsSubmitting(false);
  };

  const handleSuggestionSelection = async (value, placeId) => {
    try {
      const place = await getPlace({ placeId });
      const placeAddress = getPlaceAddress(place);

      const { state, streetName, streetNumber, suburb, postcode, unit } = placeAddress;

      const selectedAddress = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        postcode,
        state,
        street: streetNumber ? `${streetNumber} ${streetName}` : streetName,
        suburb,
        unit,
      };

      const streetConfirmationRequired = isUndefined(streetNumber);

      handleAddressSelection({ address: selectedAddress, saveToPreviousSearch: !streetConfirmationRequired });

      if (streetConfirmationRequired) {
        const streetNamePrefix = trim(value.substring(0, value.indexOf(streetName)));

        // Handling case where streetNamePrefix is the original street name as theres no street number
        const useStreetNamePrefix = streetNamePrefix !== '' && streetNamePrefix !== streetName;

        handleConfirmStreetAddress(useStreetNamePrefix ? streetNamePrefix : '');
        return;
      }

      showConfirmationModal();
    } catch (error) {
      setIsError(!!error);
    }
  };

  const searchOptions = {
    componentRestrictions: { country: countryCode.toLowerCase() },
    types: ['address'],
  };

  const handleOnChange = addressResult => {
    setAddress(addressResult);

    if (!isActive) {
      setIsActive(true);
    }

    if (!isSubmitting) {
      setIsSubmitting(true);
    }
  };

  if (googleMaps.status === 'loading') {
    return <LoadingIndicator />;
  }

  return (
    <>
      <PlacesAutocomplete
        debounce={500}
        shouldFetchSuggestions={address.length > 3}
        onChange={handleOnChange}
        onSelect={handleSuggestionSelection}
        searchOptions={searchOptions}
        value={address}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <AutocompleteInput
              loading={loading}
              getInputProps={getInputProps}
              isError={isError}
              placeholder="Start typing to search…"
            />
            {!isSubmitting && !isEmpty(predefinedPlaces) && (
              <>
                <Label fontWeight="400" mt={1} mb="0.5rem" color="greyPrimary" variant={2}>
                  Previous addresses
                </Label>
                {predefinedPlaces.map(result => (
                  <AutocompleteSuggestion
                    key={result.description}
                    onClick={() => {
                      setAddress(result.description);
                      handleAddressSelection({ address: result.address });
                      showConfirmationModal();
                    }}
                    formattedAddress={{
                      mainText: `${result.address.formattedStreet},`,
                      secondaryText: result.address.formattedSuburbStatePostcode,
                    }}
                    description={result.description}
                    role="option"
                    showDetailedListView
                    showLocationIcon
                  />
                ))}
              </>
            )}
            {isActive && !isEmpty(suggestions) && (
              <AutocompleteSuggestionWrapper handleBlur={handleBlur}>
                {suggestions.map(suggestion => (
                  <AutocompleteSuggestion
                    showLocationIcon
                    showRightIcon
                    {...getSuggestionItemProps(suggestion)}
                    formattedAddress={suggestion.formattedSuggestion}
                    description={suggestion.description}
                    key={suggestion.description}
                  />
                ))}
              </AutocompleteSuggestionWrapper>
            )}
          </>
        )}
      </PlacesAutocomplete>
    </>
  );
};

AddressAutocomplete.propTypes = {
  countryCode: PropTypes.string.isRequired,
  handleAddressSelection: PropTypes.func.isRequired,
  handleConfirmStreetAddress: PropTypes.func.isRequired,
  predefinedPlaces: PropTypes.arrayOf(
    PropTypes.shape({
      address: deliveryAddressPropTypes.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  showConfirmationModal: PropTypes.func.isRequired,
};

AddressAutocomplete.defaultProps = {
  predefinedPlaces: [],
};

export { AddressAutocomplete };
