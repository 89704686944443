import { border, space } from 'styled-system';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box } from '../../grid';
import { colors } from '../../../constants';
import { Icon } from '../../icons';
import { P, Subheading } from '../../typography';

const AutocompleteSuggestion = ({
  description,
  formattedAddress,
  showLocationIcon,
  showRightIcon,
  showDetailedListView,
  ...suggestionItemProps
}) => {
  if (showDetailedListView) {
    return (
      <Suggestion
        borderBottomStyle="solid"
        borderBottomWidth="1px"
        borderColor="grey100"
        showLocationIcon={showLocationIcon}
        py="0.5rem"
        {...suggestionItemProps}
      >
        <Box pt="0.25rem" mr={1} textAlign="center">
          <Icon name="locationMarker" width="40px" />
        </Box>
        <Box data-testid="autoCompleteSuggestion--description">
          <Subheading color="black" data-testid="autoCompleteSuggestion--formatted" variant={3}>
            {formattedAddress.mainText}
          </Subheading>
          <P variant={3} color="black">
            {formattedAddress.secondaryText}
          </P>
          {/* TODO: surface delivery notes as a subtitle */}
          {/* <P mt="0.25rem" variant={4} color="greyPrimary">
              notes
          </P> */}
        </Box>
      </Suggestion>
    );
  }

  return (
    <Suggestion
      showLocationIcon={showLocationIcon}
      type="button"
      padding={`0.875rem 1.5rem 0.875rem ${showLocationIcon ? '0.875rem' : '1.5rem'}`}
      {...suggestionItemProps}
    >
      {showLocationIcon && <Icon name="locationMarker" pr="12px" width="40px" fill="grey300" />}
      <SuggestionText data-testid="autoCompleteSuggestion--description">
        {formattedAddress ? (
          <>
            <SuggestedTextBold data-testid="autoCompleteSuggestion--formatted">
              {formattedAddress.mainText}
            </SuggestedTextBold>{' '}
            {formattedAddress.secondaryText}
          </>
        ) : (
          <>{description}</>
        )}
      </SuggestionText>
    </Suggestion>
  );
};

const Suggestion = styled.button`
  ${border}
  ${space}
  display: flex;
  text-align: left;
  color: ${colors.greyPrimary};
  width: 100%;

  :hover {
    background-color: ${colors.grey50};
  }
`;

const SuggestionText = styled(P)`
  width: calc(100% - 50px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  line-height: 24px;
`;

const SuggestedTextBold = styled.span`
  color: ${colors.black};
`;

AutocompleteSuggestion.propTypes = {
  description: PropTypes.string.isRequired,
  formattedAddress: PropTypes.shape({
    mainText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string,
  }),
  showDetailedListView: PropTypes.bool,
  showLocationIcon: PropTypes.bool,
  showRightIcon: PropTypes.bool,
};

AutocompleteSuggestion.defaultProps = {
  formattedAddress: null,
  showDetailedListView: false,
  showLocationIcon: false,
  showRightIcon: false,
};

export { AutocompleteSuggestion };
