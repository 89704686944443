import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import { restaurantsArrayPropTypes } from '@nandosaus/prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import React, { useState } from 'react';

import { AutocompleteInput } from '../autocomplete-input';
import { AutocompleteSuggestion } from '../autocomplete-suggestion';
import { AutocompleteSuggestionWrapper } from '../autocomplete-suggestion-wrapper';
import { getPlace } from '../get-place';
import { Label } from '../../typography';
import { LoadingIndicator } from '../../icons/loading-indicator';
import { RestaurantsList } from '../../restaurant-list';
import { useGoogleMaps } from '../../../hooks/use-google-maps';

const LocationAutocomplete = ({
  countryCode,
  onActive,
  onBlur,
  onRestaurantClick,
  onSelect,
  predefinedPlaces,
  showResults,
}) => {
  const [address, setAddress] = useState('');
  const [isError, setIsError] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const googleMaps = useGoogleMaps();

  const handleSuggestionSelection = async (value, placeId) => {
    try {
      setIsSubmitting(true);

      const place = await getPlace({ placeId });
      const coordinates = { latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() };

      setAddress(value);
      onSelect({ coordinates, name: value });
    } catch (error) {
      setIsError(!!error);
    }

    setIsActive(false);
    onBlur();
  };

  const handleChange = newValue => {
    setAddress(newValue);

    if (!isActive) {
      setIsActive(true);
      onActive();
    }

    if (!isSubmitting) {
      setIsSubmitting(true);
    }
  };

  const handleBlur = () => {
    setIsSubmitting(false);
    setIsActive(false);
    onBlur();
  };

  const searchOptions = {
    componentRestrictions: { country: countryCode.toLowerCase() },
  };

  if (googleMaps.status === 'loading') {
    return <LoadingIndicator />;
  }

  return (
    <PlacesAutocomplete
      autoFocus
      debounce={500}
      shouldFetchSuggestions={address.length > 3}
      onChange={handleChange}
      onSelect={handleSuggestionSelection}
      searchOptions={searchOptions}
      value={address}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <AutocompleteInput
            autoFocus
            loading={loading}
            getInputProps={getInputProps}
            isError={isError}
            placeholder="Search by location"
          />
          {!isSubmitting && !showResults && !isEmpty(predefinedPlaces) && (
            <>
              <Label fontWeight="400" mt={1} mb="0.5rem" color="greyPrimary" variant={2}>
                Previous restaurants
              </Label>
              <RestaurantsList onRestaurantClick={onRestaurantClick} restaurants={predefinedPlaces} />
            </>
          )}
          {isActive && !isEmpty(suggestions) && (
            <AutocompleteSuggestionWrapper handleBlur={handleBlur}>
              {suggestions.map(suggestion => (
                <AutocompleteSuggestion
                  showLocationIcon
                  {...getSuggestionItemProps(suggestion)}
                  formattedAddress={suggestion.formattedSuggestion}
                  description={suggestion.description}
                  key={suggestion.placeId}
                />
              ))}
            </AutocompleteSuggestionWrapper>
          )}
        </>
      )}
    </PlacesAutocomplete>
  );
};

LocationAutocomplete.propTypes = {
  countryCode: PropTypes.string.isRequired,
  onActive: PropTypes.func,
  onBlur: PropTypes.func,
  onRestaurantClick: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  predefinedPlaces: restaurantsArrayPropTypes,
  showResults: PropTypes.bool,
};

LocationAutocomplete.defaultProps = {
  onActive: () => {},
  onBlur: () => {},
  onRestaurantClick: () => {},
  predefinedPlaces: null,
  showResults: false,
};

export { LocationAutocomplete };
