import { FRAME_THEME_COLOR, MARKET } from '@nandosaus/constants';
import { observer } from 'mobx-react';
import { toUpper } from 'lodash';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { australianStates, navigation, nzRegions } from '../../constants';
import { BackgroundFrame } from '../../components/frames/background-frame';
import { Box, Col, Container, Flex, Row } from '../../components/grid';
import { ConditionalLink } from '../../components/card/conditional-link';
import { convertToSlug } from '../../utils/convert-to-slug';
import { CurrentLocationButton } from '../../components/current-location-button';
import { decals } from '../../components/decal/constants';
import { floodlightPageLoadEventPropTypes } from '../../prop-types';
import { H2, P } from '../../components/typography';
import { Layout } from '../../components/layout';
import { LocationAutocomplete } from '../../components/google-places-autocomplete';
import { makeInitialProps } from '../../utils/make-initial-props';
import { PageHeaderBlock } from '../../components/content-image';
import { seoPropDefaults, seoPropTypes } from '../../utils/extract-seo-meta-from-entry';
import { TextClamp } from '../../components/text-clamp';
import { useMarket } from '../../hooks/use-market';

const Restaurants = ({ metaContent, floodlightPageLoadEvent }) => {
  const router = useRouter();
  const isAusMarket = useMarket() === MARKET.AUSTRALIA;
  const states = isAusMarket ? australianStates : nzRegions;
  const { GREEN } = FRAME_THEME_COLOR;

  return (
    <Layout title="Restaurants" metaContent={metaContent} floodlightPageLoadEvent={floodlightPageLoadEvent}>
      <PageHeaderBlock
        heading={"Find a Nando's"}
        leftDecal={decals.restaurants.left}
        leftDecalStyle={{ height: '70%' }}
        rightDecalStyle={{ height: '70%' }}
        rightDecal={decals.restaurants.right}
        contentPosition="center"
        contentAlignment="center"
      />
      <Container flexDirection="column" flex={1}>
        <Row flexDirection={{ xs: 'column', lg: 'row' }} mb={4}>
          <Col width={{ xs: 12 / 12, lg: 6 / 12 }} mb={{ xs: 1, lg: 0 }}>
            <CurrentLocationButton
              shouldUseDefaultCoordinates={false}
              variant="primary"
              setCoordinates={({ latitude, longitude }) => {
                router.push(`${navigation.RESTAURANTS}/search?lat=${latitude}&lng=${longitude}`);
              }}
            />
          </Col>
          <Col width={{ xs: 12 / 12, lg: 6 / 12 }} mb={{ xs: 1, lg: 0 }}>
            <Box backgroundColor="white">
              <LocationAutocomplete
                autoFocus
                countryCode={process.env.locationAutocomplete.countryCode}
                onSelect={({ coordinates: { latitude, longitude } }) => {
                  router.push(`${navigation.RESTAURANTS}/search?lat=${latitude}&lng=${longitude}`);
                }}
              />
            </Box>
          </Col>
        </Row>
      </Container>
      <BackgroundFrame showFireIcon variant={GREEN} display="flex" flexDirection="column">
        <Flex flexDirection="column" width="100%">
          <Box pt={4} pb={4}>
            <H2 textAlign="center">{`You can also find a restaurant by ${isAusMarket ? 'state' : 'region'}`}</H2>
          </Box>
          <Row my={1}>
            <Col width={{ xs: 12 / 12, md: 10 / 12 }} offset={[0, 0, 1 / 12]}>
              <Row flexWrap="wrap" justifyContent="center">
                {states.map(state => (
                  <Col
                    py={{ xs: '.5rem' }}
                    key={state.value}
                    width={{ xs: 12 / 12, md: states.length <= 4 ? 3 / 12 : 4 / 12 }}
                  >
                    <ConditionalLink href={`${navigation.RESTAURANTS}/${convertToSlug(state.value)}`}>
                      <Flex bg="white" flexDirection="column">
                        <Flex width="100%" height="100%" p={1} justifyContent="center">
                          <H2>{toUpper(state.value)}</H2>
                        </Flex>
                        <Flex p="0.5rem" justifyContent="center" alignItems="center">
                          <P textAlign="center">
                            <TextClamp lines={1}>{state.longName}</TextClamp>
                          </P>
                        </Flex>
                      </Flex>
                    </ConditionalLink>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Flex>
      </BackgroundFrame>
    </Layout>
  );
};

Restaurants.getInitialProps = makeInitialProps('restaurants');

Restaurants.propTypes = {
  metaContent: PropTypes.shape(seoPropTypes),
  floodlightPageLoadEvent: floodlightPageLoadEventPropTypes,
};

Restaurants.defaultProps = {
  metaContent: seoPropDefaults,
  floodlightPageLoadEvent: undefined,
};

export default observer(Restaurants);
