import { DEFAULT_COORDINATES } from '@nandosaus/constants';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import { Alert } from '../alert';
import { Box, Flex } from '../grid';
import { Button } from '../button';
import { Icon } from '../icons';

const CurrentLocationButton = ({ setCoordinates, variant, shouldUseDefaultCoordinates }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const setDefaultCoordinates = () => {
    setCoordinates({ latitude: DEFAULT_COORDINATES.LATITUDE, longitude: DEFAULT_COORDINATES.LONGITUDE });
  };

  const success = position => {
    if (position.coords) {
      setCoordinates(position.coords);
    } else if (shouldUseDefaultCoordinates) {
      setDefaultCoordinates();
    }

    setIsLoading(false);
  };

  const error = () => {
    if (shouldUseDefaultCoordinates) {
      setErrorMessage('Location data was denied, using default location');
      setDefaultCoordinates();
    } else {
      setErrorMessage('Location data was denied, please check your browser settings');
    }
    setIsLoading(false);
  };

  const setCurrentLocation = () => {
    setIsLoading(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      setDefaultCoordinates();
      if (shouldUseDefaultCoordinates) {
        setErrorMessage('Geolocation is not supported by your browser');
      }
      setIsLoading(false);
    }
  };

  return (
    <Flex flexDirection="column">
      {/* Conditional used because children don't disappear with this setup when loading */}
      {/* TODO: change so conditional is not needed */}
      <Button variant={variant} onClick={setCurrentLocation} isLoading={isLoading}>
        {!isLoading && (
          <Flex justifyContent="center" alignItems="center">
            <Icon name="locationArrow" fill={variant === 'primary' ? 'white' : 'black'} flexShrink="0" mr="0.5rem" />
            Use current location
          </Flex>
        )}
      </Button>
      {errorMessage && (
        <Box mt="0.5rem">
          <Alert type="warning">{errorMessage}</Alert>
        </Box>
      )}
    </Flex>
  );
};

CurrentLocationButton.propTypes = {
  setCoordinates: PropTypes.func.isRequired,
  shouldUseDefaultCoordinates: PropTypes.bool,
  variant: PropTypes.string,
};

CurrentLocationButton.defaultProps = {
  shouldUseDefaultCoordinates: true,
  variant: 'secondary',
};

export { CurrentLocationButton };
