import { useScript } from '../use-script';

const useGoogleMaps = () => {
  const status = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.googlePlacesApiKey}&libraries=places`
  );

  return { status };
};

export { useGoogleMaps };
