import { head } from 'lodash';

const getPlace = async ({ placeId }) => {
  try {
    const geocoder = new window.google.maps.Geocoder();
    const geocoderResponse = await geocoder.geocode({ placeId });

    const place = head(geocoderResponse.results);

    if (!place) {
      throw new Error('Place not found');
    }

    return place;
  } catch (error) {
    throw new Error(`Fetching place details for ${placeId} failed due to: ${error}`);
  }
};

export { getPlace };
