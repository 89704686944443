import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { colors, labelSize } from '../../../constants';
import { Flex } from '../../grid';
import { Icon } from '../../icons';

const AutocompleteSuggestionWrapper = ({ handleBlur, children }) => {
  return (
    <Flex
      flexDirection="column"
      marginTop="5px"
      height="100%"
      maxHeight="250px"
      width="100%"
      border={`1px solid ${colors.grey300}`}
      boxShadow="0px 6px 12px 0px rgba(0, 0, 0, 0.2)"
    >
      <Flex justifyContent="space-between" p="24px 16px 12px">
        <SuggestionHeading fontSize={labelSize.L1MxSx}>Suggestions</SuggestionHeading>
        <Flex as="button" title="Close" type="button" onClick={handleBlur} alignItems="center">
          <Icon name="cross" height="16px" width="16px" fill="greyPrimary" marginRight="10px" />
        </Flex>
      </Flex>
      <Flex flexDirection="column" overflowY="auto" width="100%">
        {children}
      </Flex>
    </Flex>
  );
};

const SuggestionHeading = styled.div`
  text-transform: uppercase;
  font-size: ${labelSize.L1MxSx};
  color: ${colors.greyPrimary};
  letter-spacing: 0.8px;
`;

AutocompleteSuggestionWrapper.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export { AutocompleteSuggestionWrapper };
